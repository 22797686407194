import React, { useEffect, useRef, useState } from 'react';
import { StepForm } from '../../components/stepForm';

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import Webcam from 'react-webcam';
import { SelectModal } from 'src/components/modal';
import { useStorage } from 'src/global/hooks/useStorage';
import { useToast } from 'src/global/toast/useToast';
import { useProvider } from 'src/provider/provider';
import { getQuestionariosInfo } from 'src/services/services';
import { useDigitalFiles } from './useDigitalFiles';

export function PhotoCapture() {
  const navigate = useNavigate();
  const videoConstraints = {
    width: 1280, // pegar essses valores de maneira dinamica
    height: 720, // sera que é melhor uma foto quadrada ou em outro formato?
    facingMode: 'user',
  };
  const webcamRef = React.useRef<Webcam>(null);

  const [activeCam, setActiveCam] = useState(false);
  let [searchParams] = useSearchParams();
  const idSelect = searchParams.get('id');
  console.log('ID: ', idSelect);

  const [isModal, setIsModal] = useState(false);
  const [rgFrente, setRgFrente] = useState<File | null>(null);
  const [rgVerso, setRgVerso] = useState<File | null>(null);
  const [users, setUsers] = useState<any>();
  const [comprovanteResidencia, setComprovanteResidencia] =
    useState<File | null>(null);
  const [selfie, setSelfie] = useState<Blob | null>(null);
  const [outrosFiles, setOutrosFiles] = useState<File[] | null>(null);

  const { saveFiles } = useDigitalFiles();
  const { toastWarn, toastErro } = useToast();
  const {
    questionariosInfo,
    setQuestionariosInfo,
    controlModal,
    setModalControl,
  } = useProvider();
  const { removeStorage, selectStorage } = useStorage();

  const clearStorage = () => {
    removeStorage('ip');
    removeStorage('authToken');
    removeStorage('declaracaoId');
    removeStorage('questionariosInfo');
  };

  const request = async () => {
    try {
      const declaracaoIdValue = await selectStorage('declaracaoId');
      console.log('declaracaoIdValue: ', declaracaoIdValue);
      const rest = await selectStorage('IMG');
      console.log({ rest });
      const requestQuestion = await getQuestionariosInfo(
        declaracaoIdValue!,
        rest!
      );
      setQuestionariosInfo(requestQuestion?.data);
      const todosStatusOk = requestQuestion?.data.filter(
        (item: any) => item.status === 'FAIL'
      );

      // if (todosStatusOk.length === 0) {
      //   // clearStorage();
      //   // navigate(`/Finished`);
      // } else {
      //   // navigate(`/RegistrarBeneficiary`);
      // }
    } catch (error) {
      console.log('Erro: ', error);
    }
  };

  useEffect(() => {
    if (questionariosInfo) {
      const questionarioId = questionariosInfo[Number(idSelect)].nome;
      setUsers(questionarioId);
    }
  }, [idSelect, questionariosInfo]);

  console.log('users: ', users);

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const sigCanvas = useRef<any>(null);

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const ModalContent = styled('div')(
    ({ theme }) => css`
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 500;
      text-align: start;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      box-shadow: 0 4px 12px
        ${theme.palette.mode === 'dark'
          ? 'rgb(0 0 0 / 0.5)'
          : 'rgb(0 0 0 / 0.2)'};
      padding: 24px;
      color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

      & .modal-title {
        margin: 0;
        line-height: 1.5rem;
        margin-bottom: 8px;
      }

      & .modal-description {
        margin: 0;
        line-height: 1.5rem;
        font-weight: 400;
        color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
        margin-bottom: 4px;
      }
    `
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData();

    // Obtemos a imagem em base64
    if (idSelect == '0') {
      const signatureDataUrl = sigCanvas?.current?.toDataURL('image/png');
      const signatureResponse = await fetch(signatureDataUrl);
      const signatureBlob = await signatureResponse?.blob();

      if (signatureBlob) {
        formData.append('rubrica', signatureBlob, 'rubrica.png');
      } else {
        toastWarn('Rubrica precisa ser realizada');
        return;
      }
    }
    if (rgFrente) {
      formData.append('rgFrente', rgFrente);
    } else {
      toastWarn('RG/CNH frente precisa ser selecionado');
      return;
    }
    if (rgVerso) {
      formData.append('rgVerso', rgVerso);
    } else {
      toastWarn('RG/CNH verso precisa ser selecionado');
      return;
    }
    if (comprovanteResidencia) {
      formData.append('comprovanteResidencia', comprovanteResidencia);
    } else {
      toastWarn('Comprovante de residência precisa ser selecionado');
      return;
    }
    if (selfie) {
      formData.append('selfie', selfie, 'selfie.png');
    } else {
      toastWarn('Uma selfie precisa ser capturada');
      return;
    }
    if (outrosFiles) {
      outrosFiles.forEach((item) => {
        formData.append('outros', item);
      });
    }

    const questionarioId = questionariosInfo[Number(idSelect)].id;
    const pessoaId = questionariosInfo[Number(idSelect)]?.pessoaId;
    const operationId = await selectStorage('IMG');
    saveFiles(questionarioId, formData, pessoaId, operationId!)
      .then(() => {
        // setIsModal(true);
        request();
        setModalControl(!controlModal);
      })
      .catch((err) => {
        console.error(err);
        toastErro('Ocorreu algum erro ao salvar arquivos...');
      });
  }

  const capture = React.useCallback(async () => {
    if (webcamRef && webcamRef.current) {
      const selfieBase64 = webcamRef.current.getScreenshot();
      if (selfieBase64) {
        const responseSelfie = await fetch(selfieBase64);
        const blobSelfie = await responseSelfie.blob();
        setSelfie(blobSelfie);
      }
    }
  }, [webcamRef]);

  const showImagePreview = (
    file?: File | Blob | null,
    alt: string = 'preview',
    size: number = 250
  ) => {
    if (file && file.type.includes('image')) {
      return (
        <div className="p-2">
          <img src={URL.createObjectURL(file)} alt={alt} width={size} />
        </div>
      );
    }
  };

  return (
    <>
      {/* <Modal
        open={isModal}
        onClose={() => setIsModal(!isModal)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContent>
          <h2 id="unstyled-modal-title" className="modal-title">
            Cadastro realizado com sucesso!
          </h2>
         
        </ModalContent>
      </Modal> */}

      <form className="w-screen h-screen pt-10" onSubmit={handleSubmit}>
        <StepForm itemSelect={4} />

        <SelectModal
          activeModal={controlModal}
          mode={1}
          setActiveModal={() => setModalControl(!controlModal)}
        />

        {activeCam && (
          <Card sx={{ margin: 2 }}>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Capturar foto: {users}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Para a captura da foto voce precisar seguir alguns passos, não
                  use óculos de sol, biné, máscara ou qualquer coisa que cubra o
                  seu rosto.
                </Typography>
                {showImagePreview(selfie)}
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setActiveCam(!activeCam)}
              >
                Captura foto
              </Button>
            </CardActions>
          </Card>
        )}

        {!activeCam && (
          <div className="flex items-center justify-center">
            <div className="z-50 w-1/5 h-1/5 sm:w-70 sm:h-70 md:w-70 md:h-70 mt-20 absolute border-2 border-lime-300"></div>
            <div className="flex flex-col items-center justify-center">
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Capturar foto: {users}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Para a captura da foto voce precisar seguir alguns passos,
                    não use óculos de sol, biné, máscara ou qualquer coisa que
                    cubra o seu rosto.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <div className=" w-3/5">
                <div className="flex w-screen h-10 bg-black justify-center items-center">
                  <p className="text-white">Centralize o rosto a marcação</p>
                </div>
                <Webcam
                  audio={false}
                  height={720}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={1280}
                  videoConstraints={videoConstraints}
                />
              </div>
              <div className="w-2/4">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    capture();
                    setActiveCam(!activeCam);
                  }}
                >
                  Captura foto
                </Button>
              </div>
            </div>
          </div>
        )}

        <Card sx={{ margin: 2 }}>
          <CardActionArea>
            <div className="p-2">
              <Typography gutterBottom variant="h5" component="div">
                Coletar de documentos
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Para esse passo basta clicar nos botões abaixo para anexar os
                arquivos.
              </Typography>
            </div>
          </CardActionArea>
          <div className="flex flex-row p-2 justify-between flex-wrap">
            <div className="p-2 flex flex-col gap-4">
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={
                  rgFrente ? <CheckCircleOutline /> : <ErrorOutlineIcon />
                }
              >
                RG/CNH FRENTE
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*, application/pdf"
                  name="rgFrente"
                  onChange={(e) => {
                    if (
                      e.currentTarget.files &&
                      e.currentTarget.files.length > 0
                    ) {
                      setRgFrente(e.currentTarget.files.item(0));
                    }
                  }}
                />
              </Button>
              {showImagePreview(rgFrente)}
            </div>

            <div className="p-2 flex flex-col gap-4">
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={
                  rgVerso ? <CheckCircleOutline /> : <ErrorOutlineIcon />
                }
              >
                RG/CNH VERSO
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*, application/pdf"
                  name="rgVerso"
                  onChange={(e) => {
                    if (
                      e.currentTarget.files &&
                      e.currentTarget.files.length > 0
                    ) {
                      setRgVerso(e.currentTarget.files.item(0));
                    }
                  }}
                />
              </Button>
              {showImagePreview(rgVerso)}
            </div>

            <div className="p-2 gap-4 flex flex-col">
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={
                  comprovanteResidencia ? (
                    <CheckCircleOutline />
                  ) : (
                    <ErrorOutlineIcon />
                  )
                }
              >
                COMP DE RESIDÊNCIA
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*, application/pdf"
                  name="comprovanteResidencia"
                  onChange={(e) => {
                    if (
                      e.currentTarget.files &&
                      e.currentTarget.files.length > 0
                    ) {
                      setComprovanteResidencia(e.currentTarget.files.item(0));
                    }
                  }}
                />
              </Button>
              {showImagePreview(comprovanteResidencia)}
            </div>

            <div className="p-2 gap-4 flex flex-col">
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={
                  outrosFiles ? <CheckCircleOutline /> : <ErrorOutlineIcon />
                }
              >
                OUTROS
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*, application/pdf"
                  name="outros"
                  multiple
                  onChange={(e) => {
                    if (
                      e.currentTarget.files &&
                      e.currentTarget.files.length > 0
                    ) {
                      const filesArray = Array.from(e.currentTarget.files);
                      setOutrosFiles(filesArray);
                    }
                  }}
                />
              </Button>
              {/* TODO: Deixar responsivo */}
              {outrosFiles && outrosFiles.length > 0 && (
                <div className="flex gap-2 flex-wrap">
                  {outrosFiles.map(
                    (file, key) =>
                      file.type.includes('image') && (
                        <img
                          key={key + file.name}
                          alt={file.name}
                          src={URL.createObjectURL(file)}
                          width={250}
                        />
                      )
                  )}
                </div>
              )}
            </div>
          </div>
        </Card>
        <>
          {idSelect == '0' && (
            <Card sx={{ margin: 2 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Coletar assinatura
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Basta fazer assinatura de forma uniforme passando o dedo em
                    cima da área de contato.
                  </Typography>
                </CardContent>
              </CardActionArea>

              <CardActions>
                <div className="w-screen flex justify-center items-center border-2">
                  <SignatureCanvas
                    ref={sigCanvas}
                    backgroundColor="rgba(0,0,0,0)"
                    penColor="black"
                    canvasProps={{
                      width: 700,
                      height: 200,

                      className: 'sigCanvas',
                    }}
                  />
                </div>
              </CardActions>
              <div className="p-2">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => clearSignature()}
                >
                  Refazer Assinatura
                </Button>
              </div>
            </Card>
          )}
        </>

        {/* {activeCam && (
          <div className="flex items-center justify-center">
            <div className="z-50 w-80 h-80 absolute border-2 border-lime-300"></div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex w-screen h-10 bg-black justify-center items-center">
                <p className="text-white">Centralize o rosto a marcação</p>
              </div>
              <Webcam
                audio={false}
                height={720}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={1280}
                videoConstraints={videoConstraints}
              />
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  capture();
                  setActiveCam(!activeCam);
                }}
              >
                Captura foto
              </Button>
            </div>
          </div>
        )} */}
        {/* {activeCam && ( */}
        <div className="h-20 bg-white shadow-2xl relative bottom-0  w-screen flex flex-row justify-between items-center pl-4 pr-4">
          <div className="w-screen flex flex-row justify-between">
            <Button variant="contained" fullWidth type="submit">
              FINALIZAR CADASTRO
            </Button>
          </div>
        </div>
        {/* )} */}
      </form>
    </>
  );
}
