import { useState } from 'react';

export function useFlow() {
  const KEY_STORAGE = 'questionariosInfo';
  const [dataSelectUser, setDataSelectUser] = useState<any>([]);

  async function selectUser() {
    try {
      const questionariosInfo = await localStorage.getItem(KEY_STORAGE);
      setDataSelectUser(JSON.parse(questionariosInfo!));
      console.log('RETORNO: ', JSON.parse(questionariosInfo!));
      return JSON.parse(questionariosInfo!);
    } catch (error) {
      console.log('error: ', error);
    }
  }

  return {
    selectUser,
    dataSelectUser,
  };
}
