import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelectModal } from 'src/components/modal';
import { QuestionarioInfo } from 'src/dtos/questionariosInfoResponse';
import { useStorage } from 'src/global/hooks/useStorage';
import { useToast } from 'src/global/toast/useToast';
import { useProvider } from 'src/provider/provider';
import { Image } from '../../components/image';
import { useLogin } from './useLogin';

export function Login() {
  const hubhealth =
    'https://hubhealth.link/77card/ds/assets/logo_hubhealth.png';

  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue } = useForm();
  const {
    setLoadingValue,
    declaracaoId,
    questionariosInfo,
    controlModal,
    setModalControl,
  } = useProvider();
  const { toastWarn } = useToast();
  const inputDate = watch('inputDate');
  const inputCpf = watch('inputCpf');
  let [searchParams] = useSearchParams();
  const declaracaoIdLink = searchParams.get('drc');
  const operador = searchParams.get('operation');
  const { createStorage, selectStorage } = useStorage();
  const { login } = useLogin({
    inputCpf,
    inputDate,
    setValue,
    declaracaoIdLink,
  });

  useEffect(() => {
    const cpfUser = inputCpf;
    const cpfFilter = cpfUser?.replace(/[^\d]/g, '');
    console.log('cpfFilter: ', cpfFilter);
    if (cpfFilter?.length >= 11) {
      const digito1 = calcularDigito(cpfFilter, 10);
      const digito2 = calcularDigito(cpfFilter, 11);
      const resultDigit =
        digito1 === parseInt(cpfFilter[9]) &&
        digito2 === parseInt(cpfFilter[10]);
      console.log('Resultado da validacao: ', resultDigit);
      if (resultDigit === false) {
        toastWarn('Verifique o CPF digitado');
      }
    }
    console.log('cpf: ', inputCpf);
  }, [inputCpf]);

  const calcularDigito = (cpf: string, fator: number): number => {
    let soma = 0;
    for (let i = 0; i < fator - 1; i++) {
      soma += parseInt(cpf[i]) * (fator - i);
    }
    const resto = soma % 11;
    return resto < 2 ? 0 : 11 - resto;
  };

  function checkValue(value: number): void {
    if (value <= 0) {
      toastWarn('Selecione um operador válido.');
    } else if (value > 3) {
      toastWarn('Selecione um operador válido.');
    }
  }

  const imgStorage = useCallback(async () => {
    checkValue(Number(operador));
    const validateValue = operador ? operador : '0';
    await createStorage('IMG', validateValue);
  }, [operador]);

  useEffect(() => {
    imgStorage();
  }, [imgStorage]);

  const selectImage = (data: string) => {
    const dataNew = data ? data : 0;
    switch (dataNew) {
      case '2':
        return (
          <Image
            src={require('../../assets/persona.jpeg')}
            width={110}
            className="mx-auto"
            alt="Declaração Saúde"
          />
        );
        break;
      case '1':
        return (
          <Image
            src={hubhealth}
            width={180}
            className="mx-auto"
            alt="Declaração Saúde"
          />
        );
        break;
      case '3':
        return (
          <Image
            src={require('../../assets/logo_catene.PNG')}
            width={160}
            className="mx-auto"
            alt="Declaração Saúde"
          />
        );
        break;
      default:
        return <></>;
    }
  };

  const IsSubmitDisable = !inputDate && !inputCpf;
  const handleCreatedNewCycle = useCallback(async () => {
    setLoadingValue(true);
    try {
      await login({
        cpf: inputCpf,
        declaracaoSaudeId: declaracaoId,
        dtNascimento: inputDate,
      });
      setModalControl(true);
      const questionariosInfoCached = selectStorage('questionariosInfo');
      const questionariosIncompletos = (
        JSON.parse(questionariosInfoCached!) as QuestionarioInfo[]
      ).filter((item: any) => item.status === 'FAIL');
      if (
        questionariosIncompletos.filter((item) => item.tipoPessoa == 1)
          .length == 0 &&
        questionariosIncompletos.length > 0
      ) {
        // JA TEM O TITULAR
        // navigate('/ReviewDetails');
      } else {
        if (questionariosIncompletos.length == 0) {
          //COMPLETA
          // navigate('/TermsUse');
          toastWarn(
            'Declaração de saúde já foi preenchida. Não e permitido refazer a declaração já declarada.'
          );
        } else {
          //NAO TEM NADA
          // navigate('/TermsUse');
        }
      }
    } catch (err) {
      console.error(err);
      toastWarn('Proposta não encontrada');
    } finally {
      setLoadingValue(false);
    }
  }, [
    questionariosInfo,
    setLoadingValue,
    inputDate,
    login,
    inputCpf,
    declaracaoId,
    navigate,
    toastWarn,
  ]);

  if (!declaracaoIdLink || !operador) {
    return (
      <div className="w-screen h-screen bg-gray-100 flex justify-center items-center">
        <div
          onSubmit={handleSubmit(handleCreatedNewCycle)}
          className=" bg-white rounded-md flex flex-col m-5 p-10 w-[500px]"
        >
          <h2>Link quebrado :(</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen bg-gray-100 flex justify-center items-center">
      <SelectModal
        activeModal={controlModal}
        setActiveModal={() => setModalControl(!controlModal)}
      />
      <form
        onSubmit={handleSubmit(handleCreatedNewCycle)}
        className=" bg-white rounded-md flex flex-col m-5 p-10 w-[500px]"
      >
        {selectImage(operador!)}
        <TextField
          label="CPF"
          sx={{ margin: 1 }}
          required={true}
          placeholder="000.000.000-00"
          {...register('inputCpf')}
        />

        <TextField
          label="Data de Nascimento"
          sx={{ margin: 1 }}
          placeholder="DD/MM/YYYY"
          required={true}
          inputProps={{ maxLength: 10 }}
          {...register('inputDate')}
        />
        <Button
          variant="contained"
          disabled={IsSubmitDisable}
          type="submit"
          sx={{ margin: 1 }}
        >
          Login
        </Button>
      </form>
    </div>
  );
}
