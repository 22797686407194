import 'react-toastify/dist/ReactToastify.css';
import { Provide } from './provider/provider';
import { RoutesApplication } from './routes/routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LoadingOverlay } from './components/loadingOverlay';

function App() {
  return (
    <Provide>
      <BrowserRouter>
        <LoadingOverlay />
        <ToastContainer />
        <RoutesApplication />
      </BrowserRouter>
    </Provide>
  );
}

export default App;
