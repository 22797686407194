import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';
import { useStorage } from 'src/global/hooks/useStorage';
import { Finished } from 'src/pages/Finished';
import { useReviewDetails } from 'src/pages/reviewDetails/useReviewDetails';
import { useProvider } from 'src/provider/provider';
import { getQuestionariosInfo } from 'src/services/services';
import { useFlow } from '../../global/hooks/useFlow';
import { useToast } from '../../global/toast/useToast';

interface PropsSelectModal {
  activeModal: boolean;
  setActiveModal: () => void;
  mode?: number;
}

export function SelectModal({
  activeModal,
  setActiveModal,
  mode = 0,
}: PropsSelectModal) {
  const KEY_INDEX = 'indexUser';
  const KEY_USER_STATUS_FLOW = 'userFlow';
  const { selectUser, dataSelectUser } = useFlow();
  const { toastErro } = useToast();
  const { createStorage, selectStorage, removeStorage } = useStorage();
  const {
    questionariosInfo,
    setQuestionariosInfo,
    controlModal,
    setModalControl,
    declaracaoId,
  } = useProvider();
  const [indexUser, setIndex] = useState<any>(null);
  const [titularState, setTitularState] = useState('');
  const [userCompleteAll, setUserCompleteAll] = useState(false);
  const [userSelect, setUserSelect] = useState<any>();
  const navigate = useNavigate();
  const { getReviewData } = useReviewDetails();

  const refreshQuestionarioInfo = async () => {
    try {
      const operation = await selectStorage('IMG');
      await getQuestionariosInfo(declaracaoId, operation!).then((res) => {
        setQuestionariosInfo(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setIndexUser = async (val: any) => {
    await createStorage(KEY_INDEX, val);
  };

  const verfifyAllStatus = (data: any) => {
    const filter = data.every(
      (item: any) => item.lastStep === 'ARQUIVOS_ENVIADOS'
    );
    setUserCompleteAll(filter);
    console.log('filtro de estados: ', filter);
  };

  const fetchReviewData = async () => {
    try {
      const operation = await selectStorage('IMG');
      const response = await getReviewData(declaracaoId, operation!);
      await createStorage(
        KEY_USER_STATUS_FLOW,
        JSON?.stringify(response?.declaracoes)
      );
      await verifyTitula(response?.declaracoes);
      const userList = response?.declaracoes;
      const stateTitle = userList?.filter(
        (val: any) => val?.tipo === 'TITULAR'
      )!;
      console.log('mode: ', mode);
      verfifyAllStatus(userList);
      console.log('LISTA DE USUARIOS: ', userList);
      console.log('Titular:::: ', stateTitle);
      console.log(
        'ESTADO PARA CONTROLA CONTEUDO DO MODAL: ',
        stateTitle[0]?.lastStep
      );
      setTitularState(stateTitle[0]?.lastStep);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    refreshQuestionarioInfo();
    selectUser();
    fetchReviewData();
  }, [activeModal, mode]);

  // const userFlow = useCallback(() => {
  //   directFlow(flowData[Number(indexUser)]?.lastStep);
  //   setActiveModal();
  //   console.log('flowData::-: ', flowData[Number(indexUser)]?.lastStep);
  // }, [indexUser]);

  const verifyTitula = async (data: any) => {
    if (data) {
      const stateTitle = data?.filter((val: any) => val?.tipo === 'TITULAR');
      console.log('como tah: ', stateTitle[0]?.lastStep);
      // verifica se o titular ta diferente o status "ARQUIVOS_ENVIADOS" se sim define como 0 do titular
      console.log('ESTADO DE COMPARACAO: ', stateTitle[0]?.lastStep);
      if (stateTitle[0]?.lastStep !== 'ARQUIVOS_ENVIADOS') {
        console.log('entrou');
        await createStorage('userIndexSelect', 0);
        setIndexUser(0);
        setIndex(0);
      } else if (stateTitle[0]?.lastStep === 'ARQUIVOS_ENVIADOS') {
      }
    }
  };

  const buttonNext = async () => {
    console.log('como tah o titular: ', titularState);

    // rotina para titular
    // if (userTitular?.lastStep === 'ARQUIVOS_ENVIADOS') {
    //   console.log('indexUser: ', indexUser);
    //   createStorage('userIndexSelect', indexUser);
    // }

    console.log('mode: ', mode);
    console.log('indexUser: ', indexUser);
    if (mode === 1 || indexUser >= 1) {
      console.log('ITEM SELECIONADO: ', indexUser);
      await createStorage('userIndexSelect', indexUser);
      navigateFlow(`/RegistrarBeneficiary?renderer=${Math.random()}`);
    } else {
      console.log('nao e para aqui antes do depenete');
      directFlow(titularState);
    }
  };

  const clearStorage = () => {
    removeStorage('ip');
    removeStorage('authToken');
    removeStorage('declaracaoId');
    removeStorage('questionariosInfo');
  };

  /*
  LOGIN, // Logado com sucesso - Recebeu link por email: LIDIANERMOURA01@GMAIL.COM
  CONFIRMOU_TERMOS_USO, // Confirmou a leitura dos termos
  CONFIRMOU_DADOS, // Confirmou os valores e dados cadastrais
  CONFIRMOU_CARTA_ORIENTACAO, // Confirmou a leitura da Carta de Orientação da ANS
  QUESTIONARIO_FINALIZADO, // Declaração salva com sucesso, id: 1251
  ARQUIVOS_ENVIADOS, // Arquivos salvo com sucesso para a declaração


  LOGIN ou NONE - TermsUse
  CONFIRMOU_TERMOS_USO, // Confirmou a leitura dos termos
  CONFIRMOU_DADOS, // Confirmou os valores e dados cadastrais
  CONFIRMOU_CARTA_ORIENTACAO, // Confirmou a leitura da Carta de Orientação da ANS
  QUESTIONARIO_FINALIZADO, // Declaração salva com sucesso, id: 1251
  ARQUIVOS_ENVIADOS, // Arquivos salvo com sucesso para a declaração
  */

  useEffect(() => {
    console.log('userCompleteAll: ', userCompleteAll);
    if (userCompleteAll) {
      console.log('FINALIZOU TODO O FLUXO');
      navigate('/Finished');
      setModalControl(false);
      clearStorage();
    }
  }, [userCompleteAll]);

  const navigateFlow = (router: string) => {
    setActiveModal();
    navigate(router);
  };

  const directFlow = (flow: string) => {
    switch (flow) {
      case 'NONE':
      case 'LOGIN':
        console.log('LOGIN---');
        navigateFlow('/TermsUse');
        break;
      case 'CONFIRMOU_TERMOS_USO':
        navigateFlow('/ReviewDetails');
        console.log('CONFIRMOU_TERMOS_USO');
        break;
      case 'CONFIRMOU_DADOS':
        console.log('CONFIRMOU_DADOS');
        navigateFlow('/LetterBeneficiary');
        break;
      case 'CONFIRMOU_CARTA_ORIENTACAO':
        console.log('CONFIRMOU_CARTA_ORIENTACAO');
        navigateFlow('/RegistrarBeneficiary');
        break;
      case 'QUESTIONARIO_FINALIZADO':
        console.log('QUESTIONARIO_FINALIZADO');
        navigateFlow('/PhotoCapture?id=0');
        break;
      case 'ARQUIVOS_ENVIADOS':
        console.log('ARQUIVOS_ENVIADOS');
        break;
      default:
    }
  };

  const titleValidate = (titleSelect: boolean) => {
    return titleSelect
      ? 'Selecione o usuário para realizar a coletas das informações'
      : 'Vamos precisar coletar as informações do titular primeiramente';
  };

  return (
    <Modal
      styles={{
        modal: { padding: 0, borderRadius: 1 },
      }}
      open={activeModal}
      onClose={setActiveModal}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      {!userCompleteAll ? (
        <div className="xl:w-4/4 sm:w-4/4 ">
          <div className="shadow-2xl rounded-md flex flex-col">
            <div className="w-auto h-20 bg-blue-400 flex justify-center items-center">
              <p className="text-center text-blue-50 font-bold text-lg pl-1 pr-1">
                {titleValidate(titularState === 'ARQUIVOS_ENVIADOS')}
              </p>
            </div>
            <div className="w-full flex-row p-1 mt-9 mb-9">
              {/* verificar com o gui o pro que o status nao ta atualizando */}
              {titularState === 'ARQUIVOS_ENVIADOS' ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Seleção</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            display: {
                              xs: 'none',
                              sm: 'table-cell',
                              md: 'table-cell',
                              lg: 'table-cell',
                              xl: 'table-cell',
                            },
                          }}
                        >
                          CPF
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            display: {
                              xs: 'none',
                              sm: 'table-cell',
                              md: 'table-cell',
                              lg: 'table-cell',
                              xl: 'table-cell',
                            },
                          }}
                        >
                          ID
                        </TableCell>
                        <TableCell align="center">STATUS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataSelectUser?.map((row: any, index: number) => (
                        <TableRow
                          key={row.nome}
                          onClick={() => {
                            console.log('1-###: ', index);
                            const val = row.status === 'OK' ? null : index;
                            if (val != null) {
                              setIndex(val);
                              setIndexUser(val);
                              console.log('2-### val : ', val);
                              setUserSelect(row);
                            }
                            if (row.status === 'OK') {
                              toastErro(
                                'Usuário já realizado a declaração de saúde'
                              );
                            }
                          }}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <Checkbox
                              disabled={
                                index === 0 ? row?.status === 'OK' : false
                              }
                              checked={
                                index === 0
                                  ? true
                                  : row?.status === 'OK'
                                  ? true
                                  : row.cpf == userSelect?.cpf
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <AccountCircleIcon sx={{ marginRight: 1 }} />
                            {row.nome}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              display: {
                                xs: 'none',
                                sm: 'table-cell',
                                md: 'table-cell',
                                lg: 'table-cell',
                                xl: 'table-cell',
                              },
                            }}
                          >
                            {row.cpf}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              display: {
                                xs: 'none',
                                sm: 'table-cell',
                                md: 'table-cell',
                                lg: 'table-cell',
                                xl: 'table-cell',
                              },
                            }}
                          >
                            {row.id}
                          </TableCell>
                          <TableCell align="center">
                            {row?.status == 'OK' ? (
                              <TaskAltIcon color="success" />
                            ) : (
                              <ErrorOutlineIcon color="error" />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                ModalFormSelect(dataSelectUser)
              )}
            </div>
            <span className="p-1">
              <Button
                variant="contained"
                fullWidth
                // disabled={indexUser}
                onClick={() => buttonNext()}
              >
                Próximo
              </Button>
            </span>
          </div>
        </div>
      ) : (
        <>
          <Finished />
        </>
      )}
    </Modal>
  );
}

const ModalFormSelect = (dataSelectUser: any) => {
  return (
    <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Seleção</TableCell>
            <TableCell>Nome</TableCell>

            <TableCell
              sx={{
                display: {
                  xs: 'none',
                  sm: 'table-cell',
                  md: 'table-cell',
                  lg: 'table-cell',
                  xl: 'table-cell',
                },
              }}
              align="center"
            >
              CPF
            </TableCell>
            <TableCell
              sx={{
                display: {
                  xs: 'none',
                  sm: 'table-cell',
                  md: 'table-cell',
                  lg: 'table-cell',
                  xl: 'table-cell',
                },
              }}
              align="center"
            >
              ID
            </TableCell>
            <TableCell align="center">STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSelectUser?.map((row: any, index: number) => (
            <TableRow
              key={row.nome}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right">
                <Checkbox disabled={index > 0} checked={true} />
              </TableCell>
              <TableCell component="th" scope="row">
                <AccountCircleIcon sx={{ marginRight: 1 }} />
                {row.nome}
              </TableCell>
              <TableCell
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'table-cell',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                  },
                }}
                align="right"
              >
                {row.cpf}
              </TableCell>
              <TableCell
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'table-cell',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                  },
                }}
                align="right"
              >
                {row.id}
              </TableCell>
              <TableCell align="center">
                {row?.status == 'OK' ? (
                  <TaskAltIcon color="success" />
                ) : (
                  <ErrorOutlineIcon color="error" />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
