import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useState } from 'react';

enum CardQuestionType {
  YES_NO = 1,
  TEXT = 2,
}
interface PropsCardQuestion {
  question: string;
  change: (val: any) => void;
  observation: (val: any) => void;
  type: CardQuestionType;
  onErro: boolean;
}

export function CardQuestion({
  change,
  question,
  observation,
  type,
  onErro = false,
  ...rest
}: PropsCardQuestion) {
  const [alignment, setAlignment] = React.useState<string | null>('');

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    change(newAlignment);
    setAlignment(newAlignment);
  };

  const [state, setState] = useState();

  const handleChange = (text: any) => {
    const data = text?.currentTarget?.value;
    console.log('data', data);
    const numericText = data?.replace(/[^0-9.]/g, '');
    console.log('testeff: ', numericText);
    setState(numericText);
    return numericText;
  };

  const handleChangeNot = (text: any) => {
    const data = text?.currentTarget?.value;
    console.log('data', data);
    const numericText = data?.replace(/[^0-9.]/g, '');
    console.log('testeff: ', numericText);
    setState(data);
    return data;
  };

  const renderText = () => {
    return (
      <div className="p-4">
        <TextField
          id="outlined-multiline-static"
          type="number"
          value={state}
          onChange={(val) => observation(handleChange(val))}
          multiline
          fullWidth
        />
      </div>
    );
  };
  const renderYesNo = () => {
    return (
      <>
        <div className="p-4">
          <ToggleButtonGroup
            fullWidth
            value={alignment}
            exclusive
            color="primary"
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="S" aria-label="left aligned">
              <p className="font-bold">Sim</p>
            </ToggleButton>
            <ToggleButton value="N" aria-label="centered">
              <p className="font-bold">Não</p>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {alignment === 'S' && (
          <div className="m-4">
            <TextField
              id="outlined-multiline-static"
              label="Observações"
              onChange={(val) => observation(handleChangeNot(val))}
              multiline
              rows={4}
              fullWidth
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={`w-auto  flex flex-col mt-4 mb-4 ${
        onErro ? 'border-red-600	border-2' : 'shadow'
      } `}
    >
      <p className="text-lg text-justify text-wrap p-4">{question}</p>
      {type === CardQuestionType.YES_NO ? renderYesNo() : renderText()}
    </div>
  );
}
